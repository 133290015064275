<template>

<div>

	<com-title text="Pages" />

	<com-item route="Convention.Pages" :ignoreActive="isNotPages" text="Pages" :count="$convention.count.pages.pages" />
	<com-item route="Convention.Pages.Captures" text="Captures" :count="$convention.count.pages.captures" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../..//panel/common/Title'),
		'com-item': () => import('./../..//panel/common/Item')
	},

	computed: {

		isNotPages: function() {

			return this.$route.name.toLowerCase().indexOf('capture') > 0

		}

	}

}

</script>

<style scoped>

</style>
